<template>
  <div class="container-fluid">
    <div class="row justify-content-end">
      <!--<router-link :to="{ name: 'accounts.sermons.import.youtube' }" class="btn btn-xs btn-danger mg-b-15 mg-r-10 float-right">Import from Youtube</router-link>-->
      <router-link :to="{ name: 'accounts.sermons.create' }" class="btn btn-xs btn-primary mg-b-15 float-right">{{ $t('sermons.create')}}</router-link>
    </div>
    <div class="placeholder-paragraph aligned-centered" v-if="loading">
      <div class="line"></div>
      <div class="line"></div>
    </div>
    <div class="row" v-if="!loading">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Topics</th>
          <th scope="col">Date</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="sermon in sermons" v-bind:key="sermon.id">
          <td>
            <router-link :to="{name: 'accounts.sermons.edit', params: {id: sermon.id}}">{{ sermon.title }}</router-link>
          </td>
          <td>{{ topics(sermon) }}</td>
          <td>{{ sermon.date|format_date }}</td>
          <td >
            <fa :icon="['fa', 'trash-alt']" v-on:click="deleteSermon(sermon)"/>
          </td>
        </tr>
        </tbody>
      </table>

      <Pagination v-bind:meta="meta" v-on:pageChanged="onPageChanged"></Pagination>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import _ from 'lodash'
  import Pagination from '~/components/Pagination'
  
  export default {
    middleware: 'auth',
    components: {
      Pagination
    },
    data: () => ({
      loading: true,
      meta: {},
      limit: 30,
      page: 1,
      sermons: []
    }),
    computed: mapGetters({
      church: 'account/church',
    }),
    created() {
      this.refresh();
    },
    methods: {
      topics(sermon) {
        return _.join(_.map(sermon.topics, 'text'), ', ');
      },
      refresh() {
        let vm = this;
        this.loading = true;
        this.$store.dispatch('account/fetchSermons', {limit: this.limit, page: this.page})
          .then(({ meta, sermons }) => { 
            vm.loading = false; 
            vm.meta = meta;
            vm.sermons = sermons;
          });
      },
      onPageChanged (page) {
        this.page = page;
        this.refresh();
      },
      async deleteSermon(sermon) {   
        try{
          this.loading = true;

          await axios.delete('/api/v1/user/me/sermons/' +  sermon.id);

          this.refresh();
        } catch (e) {
          // this.$router.push({name: 'accounts.church.invite'});
        }
      },
    }
  }
</script>
